



































import { SessionClosingInfoProperties } from "./SessionClosingInfoView.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import DateUtil from "@/utilities/DateUtil";

@Component({})
export default class SessionClosingMainView extends Vue {
	@Prop() private properties: SessionClosingInfoProperties;
	
    public get sessionClosing() {
        return this.properties.sessionClosing;
    }

    public get closingDate() {
        const v = this.sessionClosing.closingDate;
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public get finalizedDate() {
        const v = this.sessionClosing.finalizedDate;
        return v ? DateUtil.format(new Date(v), this.properties.dateTimeFormat) : "";
    }

    public get status() {
        const v = this.sessionClosing.status;
        if (v === "Open") {
            return this.$t("text.open");
        } else if (v === "Finalized") {
            return this.$t("text.finalized");
        }
        return this.$t(v);
    }

}
